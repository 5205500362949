import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CSectTitle,
  CHeroMedia02,
  CKv,
  CGoogleMap,
  CBgMedia,
  CInfinityMedia,
  CPickup,
  CNewsList,
  AssetImage,
  CBtn,
  CBtnList,
  CFixedImg,
  CFixBtnList02,
  CHeroMedia03,
} from '../components/_index';
import infoChoice from '../utils/info-choice';
import infoGet from '../utils/info-get';
import pickupGet from '../utils/pickup-get';
import { pickupChoice } from '../utils/pickup-choice';
import { text } from 'stream/consumers';
import '../assets/_sass/page/home.scss';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEO ogtype={'website'} />
      <CKv
        title={
          <>
            WELCOME
            <br />
            YOKOHAMA
            <br />
            SKY
            <br />
            RESORT
          </>
        }
        data={[
          {
            img: {
              src: '/assets/images/home/kv.png',
            },
            imgSp: {
              src: '/assets/images/home/kv__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv02.png',
            },
            imgSp: {
              src: '/assets/images/home/kv02__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv03.png',
            },
            imgSp: {
              src: '/assets/images/home/kv03__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv04.png',
            },
            imgSp: {
              src: '/assets/images/home/kv04__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv05.png',
            },
            imgSp: {
              src: '/assets/images/home/kv05__sp.png',
            },
          },
        ]}
      />
      <div className="l_sect">
        <section className="u_mbLarge" style={{ overflow: 'hidden' }}>
          <LWrap>
            <CSectTitle title={{ ja: 'ピックアップ', en: 'PICKUP' }} />
            <CPickup data={pickupChoice(pickupGet(), ['TOPページ'], 0)} />
          </LWrap>
        </section>
        <section className="u_mbLarge">
          <LWrap>
            <CHeroMedia03
              tag="h3"
              data={[
                {
                  img: {
                    src: '/assets/images/home/close_logo.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      横浜ロイヤルパークホテルは、
                      <br className="u_sp" />
                      2025年3月末に一時営業休止。
                      <br />
                      2028年以降、
                      <br className="u_sp" />
                      リニューアルオープンいたします。
                    </>
                  ),
                  text: (
                    <>
                      横浜ロイヤルパークホテルは、
                      <br className="u_sp" />
                      横浜とともに未来へ。
                      <br />
                      多くの方々に愛された景色、
                      <br className="u_sp" />
                      伝統を受け継ぎつつ、
                      <br className="u_pc" />
                      より華やかに、
                      <br className="u_sp" />
                      より記憶に残る時間をお届けしたい。
                      <br />
                      そんな想いを胸に、
                      <br />
                      これまで以上に愛されるホテルへ
                      <br className="u_sp" />
                      生まれ変わります。
                      <br />
                      営業休止前の貴重な時間が、
                      <br className="u_sp" />
                      思い出の時間になるよう、
                      <br />
                      心からのおもてなしをご用意し、
                      <br />
                      みなさまのご来館をお待ちしております。
                    </>
                  ),
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: 'イベント特設ページ',
                  link: {
                    href: 'https://www.yrph.com/information/closingevent/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <div className="u_mbLarge">
          <LWrap>
            <CBgMedia
              data={[
                {
                  img: {
                    src: '/assets/images/home/img_stay.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_stay__sp.png',
                  },
                  title: <>STAY</>,
                  catchCopy: (
                    <>
                      横浜ランドマークタワー
                      <br />
                      52Fから67Fに位置する客室
                    </>
                  ),
                  text: (
                    <>
                      すべてのお部屋がゆったりと寛げるインターナショナルサイズ。
                      <br />
                      広がる空と港町ヨコハマの眺望とご一緒に、非日常の時間をお過ごしください。
                    </>
                  ),
                  btnList: [
                    {
                      label: <>ご宿泊</>,
                      link: {
                        href: '/stay/',
                      },
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/home/img_restaurant.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_restaurant__sp.png',
                  },
                  title: (
                    <>
                      RESTAURANT
                      <br />& BAR
                    </>
                  ),
                  catchCopy: (
                    <>
                      地上277m、ホテル最上階のスカイラウンジ
                      <br />
                      68Fに位置する和・洋・中のレストラン
                    </>
                  ),
                  text: (
                    <>
                      ビジネスやプライベートのさまざまなシーンにご利用いただける飲食施設をご用意。大切な日の会食に、ご接待にご利用ください。
                    </>
                  ),
                  btnList: [
                    {
                      label: <>レストラン＆バー</>,
                      link: {
                        href: '/restaurants/',
                      },
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/home/img_anniversary.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_anniversary__sp.png',
                  },
                  title: <>ANNIVERSARY</>,
                  catchCopy: (
                    <>
                      記念日は
                      <br />
                      横浜ロイヤルパークホテルで
                    </>
                  ),
                  text: (
                    <>
                      出会ったあの日を記念して。
                      <br />
                      大切な方のお誕生日に、
                      <br />
                      気持ちを込めてあなたらしく。
                    </>
                  ),
                  btnList: [
                    {
                      label: <>お祝い・記念日</>,
                      link: {
                        href: '/anniversary/',
                      },
                    },
                  ],
                },
              ]}
            />
          </LWrap>
        </div>
      </div>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle
            title={{
              en: 'WEDDING',
            }}
          />
          <CInfinityMedia
            data={[
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/home/img_wedding.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/home/img_wedding02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/home/img_wedding03.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    いつの時代にも
                    <br />
                    変わらない美しさを湛えて
                  </>
                ),
                text: (
                  <>
                    厳かな格式ある誓いの儀式として挙式をとり行う正統派なチャペル。
                    <br />
                    みなとみらいの空に向かい誓う大切な方々に囲まれて未来を誓う光の中のセレモニー。
                    <br />
                    ここからおふたりの未来が
                    <br />
                    スタートしていきます。
                  </>
                ),
                btn: {
                  label: <>ウエディング</>,
                  link: {
                    href: '/wedding/',
                  },
                },
              },
            ]}
          />
          <CBgMedia
            exClass="banquet"
            reverse={true}
            data={[
              {
                img: {
                  src: '/assets/images/home/img_banquet.png',
                },
                imgSp: {
                  src: '/assets/images/home/img_banquet__sp.png',
                },
                title: <>BANQUET</>,
                catchCopy: (
                  <>
                    横浜市内最大級の大宴会場
                    <br />
                    日本で一番高い場所にある
                    <br />
                    スカイバンケットルーム
                  </>
                ),
                text: (
                  <>
                    横浜市内最大級の宴会場「鳳翔」をはじめとした大小12の宴会場。
                    <br />
                    数多くの宴席を手がけてきた熟練のシェフが腕をふるうお料理。
                    <br />
                    お客様の大切なひとときを、各界のVIPをお迎えしてきた経験豊かなスタッフがお手伝いたします。
                  </>
                ),
                btnList: [
                  {
                    label: <>宴会・会議</>,
                    link: {
                      href: '/banquet/',
                    },
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle
            title={{
              en: 'ABOUT US',
            }}
          />
          <CInfinityMedia
            data={[
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/home/img_concept.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/home/img_concept02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/home/img_concept03.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    日常を忘れて、
                    <br />
                    寛ぎのひとときを
                  </>
                ),
                text: (
                  <>
                    充実の施設と行き届いたサービスでスカイリゾートの時間をお楽しみください。
                  </>
                ),
                btn: {
                  label: <>当ホテルについて</>,
                  link: {
                    href: '/about/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className={`l_sect`}>
        <LWrap>
          <CSectTitle title={{ ja: '', en: 'INFORMATION' }} />
          <CNewsList data={infoChoice(infoGet(), [], 5)} />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_pt0">
        <LWrap>
          <CSectTitle
            title={{
              en: 'ACCESS',
            }}
          />
          <div className="p_topAccess">
            <div className="p_topAccess_header">
              <h3 className="p_topAccess_title">
                横浜観光の
                <br />
                拠点に
              </h3>
              <p className="p_topAccess_text">
                横浜の周辺施設へのアクセスも抜群。
                <br />
                中華街へも、赤レンガ倉庫にも、山下公園へも、
                <br className="u_sp" />
                車なら約10分以内、徒歩でも約30分以内の立地です。
              </p>
              <div className="p_topAccess_bg u_sp">
                <AssetImage src="/assets/images/home/img_access.svg" alt="" />
              </div>
            </div>
            <div className="p_topAccess_body">
              <CGoogleMap />
              <div className="p_topAccess_bg u_pc">
                <AssetImage src="/assets/images/home/img_access.svg" alt="" />
              </div>
            </div>
            <div className="p_topAccess_footer">
              <p className="p_topAccess_footer_text">
                〒220-8173 横浜市西区みなとみらい2-2-1-3
                <br />
                TEL <a href="tel:0452211111">045-221-1111</a>（代表）
              </p>
              <ul className="p_topAccess_btnList">
                <li>
                  <CBtn label="アクセス" link={{ href: '/about/access/' }} />
                </li>
                <li>
                  <CBtn
                    label="Google Maps"
                    link={{
                      href: process.env.GOOGLE_MAPS_URL || '',
                      blank: true,
                    }}
                    color="borderBlack"
                  />
                </li>
              </ul>
            </div>
          </div>
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <CFixBtnList02
        data={[
          {
            img: {
              src: '/assets/images/home/Journal.jpg',
              alt: '',
            },
            link: {
              href: 'https://www.royalparkhotels.co.jp/journal/article13/',
              blank: true,
            },
            size: {
              width: '300px',
              height: '300px',
            },
          },
        ]}
      />
    </Layout>
  );
};

export default IndexPage;
